import _Error from 'isotropic-error';
import _later from 'isotropic-later';
import _make from 'isotropic-make';
import _Pubsub from 'isotropic-pubsub';
import _request from 'request';
import _xml2js from 'xml2js';

export default _make(_Pubsub, {
    request (config) {
        const promise = new Promise((resolve, reject) => {
            config = {
                complete: false,
                queued: false,
                reject,
                requestConfig: config,
                requestRetryCount: 0,
                resolve
            };

            this._queueRequest(config);
        });

        promise.cancel = () => {
            if (config.complete) {
                return;
            }

            config.complete = true;

            config.reject(_Error({
                details: {
                    config: config.requestConfig
                },
                message: 'Request aborted'
            }));

            if (config.request) {
                config.request.abort();
            }
        };

        return promise;
    },
    _afterQueueRequest ({
        data
    }) {
        if (data.queued) {
            this._beginRequestThrottleInterval();
        }
    },
    _beginRequestThrottleInterval () {
        if (this._requestThrottleTimer) {
            return;
        }

        const sinceLastRequest = Date.now() - this._lastRequestTime;

        if (sinceLastRequest >= this._requestThrottleInterval) {
            this._dequeueRequest();
        } else {
            this._requestThrottleTimer = _later(this._requestThrottleInterval - sinceLastRequest, () => {
                this._requestThrottleTimer = null;
                this._dequeueRequest();
            });
        }
    },
    _dequeueRequest () {
        this._publish('dequeueRequest');
    },
    _eventDequeueRequest () {
        let config;

        do {
            config = this._requestQueue.shift();

            if (!config) {
                return;
            }

            config.queued = false;
        } while (config.complete);

        this._request(config);
    },
    _eventNonSuccessfulStatusCodeError ({
        data: {
            config,
            error,
            response
        }
    }) {
        if (config.complete) {
            return;
        }

        if (this._retryRequestOnNonSuccessfulStatusCodeError && config.requestRetryCount < this._maximumRequestRetryCount) {
            this._retryRequest(config);
            return;
        }

        config.complete = true;
        config.reject(_Error({
            details: {
                maximumRequestRetryCount: this._retryRequestOnNonSuccessfulStatusCodeError ?
                    this._maximumRequestRetryCount :
                    0,
                requestRetryCount: config.requestRetryCount,
                response
            },
            error,
            message: 'Retries exhausted'
        }));
    },
    _eventQueueRequest ({
        data
    }) {
        if (!data.complete) {
            data.queued = true;
            this._requestQueue.push(data);
        }
    },
    _eventRequest ({
        data
    }) {
        const {
            requestConfig: {
                headers = {},
                json,
                xml,
                ...requestConfig
            }
        } = data;

        if (data.complete) {
            this._beginRequestThrottleInterval();
            return;
        }

        if (json) {
            if (typeof requestConfig.body !== 'string') {
                requestConfig.body = JSON.stringify(requestConfig.body);
            }

            if (!headers['Content-Type']) {
                headers['Content-Type'] = 'application/json';
            }
        }

        if (!headers['User-Agent'] && typeof window === 'undefined') {
            headers['User-Agent'] = 'nodejs';
        }

        requestConfig.headers = headers;

        this._lastRequestTime = Date.now();

        data.request = _request(requestConfig, (error, response, body) => {
            data.request = null;

            if (data.complete) {
                return;
            }

            if (error) {
                this._publish('requestError', {
                    config: data,
                    error: _Error({
                        details: {
                            config: requestConfig
                        },
                        error,
                        message: 'Error making request'
                    })
                });
            } else {
                this._publish('response', {
                    body,
                    config: data,
                    json,
                    response,
                    xml
                });
            }
        });

        this._beginRequestThrottleInterval();
    },
    _eventRequestError ({
        data: {
            config,
            error
        }
    }) {
        if (config.complete) {
            return;
        }

        if (this._retryRequestOnRequestError && config.requestRetryCount < this._maximumRequestRetryCount) {
            this._retryRequest(config);
            return;
        }

        config.complete = true;
        config.reject(_Error({
            details: {
                maximumRequestRetryCount: this._retryRequestOnRequestError ?
                    this._maximumRequestRetryCount :
                    0,
                requestRetryCount: config.requestRetryCount
            },
            error,
            message: 'Retries exhausted'
        }));
    },
    async _eventResponse ({
        data: {
            body,
            config,
            json,
            response,
            xml
        }
    }) {
        if (config.complete) {
            return;
        }

        response.rawBody = body;

        if (body) {
            if (json) {
                try {
                    if (Buffer.isBuffer(body)) {
                        body = body.toString('utf8');
                    } else if (typeof body !== 'string') {
                        throw _Error({
                            message: 'Response body isn\'t a string'
                        });
                    }

                    body = JSON.parse(body);
                } catch (error) {
                    this._publish('responseError', {
                        config,
                        error: _Error({
                            details: {
                                body,
                                config: config.requestConfig,
                                response
                            },
                            message: 'Error parsing JSON response'
                        }),
                        response
                    });

                    return;
                }
            } else if (xml) {
                try {
                    if (Buffer.isBuffer(body)) {
                        body = body.toString('utf8');
                    } else if (typeof body !== 'string') {
                        throw _Error({
                            message: 'Response body isn\'t a string'
                        });
                    }

                    body = await new Promise((resolve, reject) => {
                        _xml2js.parseString(body, (error, body) => {
                            if (error) {
                                reject(_Error({
                                    error,
                                    message: 'XML parser error'
                                }));
                            } else {
                                resolve(body);
                            }
                        });
                    });
                } catch (error) {
                    this._publish('responseError', {
                        config,
                        error: _Error({
                            details: {
                                body,
                                config: config.requestConfig,
                                response
                            },
                            message: 'Error parsing XML response'
                        }),
                        response
                    });

                    return;
                }
            }
        }

        response.body = body;

        if (this._rejectNonSuccessfulStatusCode && response.statusCode >= 400) {
            this._publish('nonSuccessfulStatusCodeError', {
                config,
                error: _Error({
                    details: {
                        body,
                        config: config.requestConfig,
                        response,
                        statusCode: response.statusCode
                    },
                    message: 'Non-successful status code'
                }),
                response
            });

            return;
        }

        config.complete = true;
        config.resolve(response);
    },
    _eventResponseError ({
        data: {
            config,
            error,
            response
        }
    }) {
        if (config.complete) {
            return;
        }

        if (this._retryRequestOnResponseError && config.requestRetryCount < this._maximumRequestRetryCount) {
            this._retryRequest(config);
            return;
        }

        config.complete = true;
        config.reject(_Error({
            details: {
                maximumRequestRetryCount: this._retryRequestOnResponseError ?
                    this._maximumRequestRetryCount :
                    0,
                requestRetryCount: config.requestRetryCount,
                response
            },
            error,
            message: 'Retries exhausted'
        }));
    },
    _init (...args) {
        Reflect.apply(_Pubsub.prototype._init, this, args);

        const {
            maximumRequestRetryCount = 1,
            rejectNonSuccessfulStatusCode = true,
            requestCountPerRequestThrottleInterval = 1,
            requestRetryDelay = 1597,
            requestThrottleInterval = 200,
            retryRequestOnNonSuccessfulStatusCodeError = false,
            retryRequestOnRequestError = true,
            retryRequestOnResponseError = false
        } = args[0] || {};

        this._lastRequestTime = -Infinity;
        this._maximumRequestRetryCount = maximumRequestRetryCount;
        this._rejectNonSuccessfulStatusCode = rejectNonSuccessfulStatusCode;
        this._requestQueue = [];
        this._requestRetryDelay = requestRetryDelay;
        this._requestThrottleInterval = requestThrottleInterval / requestCountPerRequestThrottleInterval;
        this._requestThrottleTimer = null;
        this._retryRequestOnNonSuccessfulStatusCodeError = retryRequestOnNonSuccessfulStatusCodeError;
        this._retryRequestOnRequestError = retryRequestOnRequestError;
        this._retryRequestOnResponseError = retryRequestOnResponseError;

        this._after('queueRequest', '_afterQueueRequest');

        return this;
    },
    _queueRequest (config) {
        this._publish('queueRequest', config);
    },
    _request (config) {
        this._publish('request', config);
    },
    _retryRequest (config) {
        _later(this._requestRetryDelay, () => {
            config.requestRetryCount += 1;
            this._queueRequest(config);
        });
    }
}, {
    _events: {
        dequeueRequest: {
            allowPublicPublish: false,
            defaultFunction: '_eventDequeueRequest'
        },
        nonSuccessfulStatusCodeError: {
            allowPublicPublish: false,
            defaultFunction: '_eventNonSuccessfulStatusCodeError'
        },
        queueRequest: {
            allowPublicPublish: false,
            defaultFunction: '_eventQueueRequest'
        },
        request: {
            allowPublicPublish: false,
            defaultFunction: '_eventRequest'
        },
        requestError: {
            allowPublicPublish: false,
            defaultFunction: '_eventRequestError'
        },
        response: {
            allowPublicPublish: false,
            defaultFunction: '_eventResponse'
        },
        responseError: {
            allowPublicPublish: false,
            defaultFunction: '_eventResponseError'
        }
    }
});
