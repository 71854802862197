import './datetime-input-component.css';
import 'vue-datetime/dist/vue-datetime.css';
import _DatetimeComponent from './datetime-component.js';
import _luxonSettings from './luxon-settings.js';
import _moment from 'moment-timezone';
import _parseUserDateTime from './parse-user-date-time.js';
import _timeZone from '../js/time-zone.js';
import _Vue from 'vue/dist/vue.esm.js';

_luxonSettings.defaultLocale = 'en';

export default _Vue.extend({
    components: {
        datetime: _DatetimeComponent
    },
    computed: {
        calendarValue () {
            return this.internalValue && (
                this.type === 'date' ?
                    _moment.tz(this.internalValue, 'YYYY-MM-DD', true, _timeZone).toISOString() :
                    _moment.tz(this.internalValue, 'YYYY-MM-DD hh:mm A', true, _timeZone).toISOString()
            );
        }
    },
    data () {
        return {
            fixInputId: () => {
                const id = this.$el.id;

                if (id) {
                    this.$el.removeAttribute('id');
                    this.inputId = id;
                    return true;
                }
            },
            inputId: this.id,
            internalValue: this.getInternalValueFromValue(this.value),
            invalidValue: null
        };
    },
    methods: {
        getInternalValueFromValue (value) {
            return value && (
                this.type === 'date' ?
                    _moment.tz(value, 'YYYY-MM-DD', true, _timeZone).format('YYYY-MM-DD') :
                    _moment(value, _moment.ISO_8601, true).tz(_timeZone).format('YYYY-MM-DD hh:mm A')
            );
        },
        onCalendarClick (event) {
            const inputElement = event.target.getElementsByTagName('input')[0];

            if (inputElement) {
                inputElement.click();
            }
        },
        onCalendarInput (value) {
            if (!value) {
                return;
            }

            value = _moment(value, _moment.ISO_8601, true).tz(_timeZone).startOf('minute');
            this.internalValue = value.format(
                this.type === 'date' ?
                    'YYYY-MM-DD' :
                    'YYYY-MM-DD hh:mm A'
            );
            this.invalidValue = null;
            this.$emit(
                'input',
                this.type === 'date' ?
                    value.format('YYYY-MM-DD') :
                    value.toISOString()
            );
        },
        onValueChange (event) {
            const internalValue = event.target.value,
                value = internalValue && _parseUserDateTime(internalValue);

            event.target.setCustomValidity(
                !value || value.isValid() ?
                    '' :
                    'Please enter a valid date and time.'
            );

            if (event.target.validity.valid) {
                this.internalValue = internalValue;
                this.invalidValue = null;
            } else {
                this.internalValue = null;
                this.invalidValue = event.target.value;
            }

            this.$emit(
                'input',
                value && (this.type === 'date' ?
                    value.format('YYYY-MM-DD') :
                    value.toISOString())
            );
        }
    },
    mounted () {
        if (!this.id && this.fixInputId()) {
            this.$parent.$on('hook:updated', this.fixInputId);
            this.$on('hook:destroyed', () => {
                this.$parent.$off('hook:updated', this.fixInputId);
            });
        }
    },
    name: 'datetime-input',
    props: {
        id: {
            type: String
        },
        maxDatetime: {
            type: String
        },
        minDatetime: {
            type: String
        },
        name: {
            type: String
        },
        placeholder: {
            type: String
        },
        type: {
            default: 'datetime',
            type: String
        },
        value: {
            type: String
        }
    },
    template: `
        <div class="datetime-input justify-content">
            <i class="fa fa-calendar pointer" v-on:click="onCalendarClick">
                <datetime input-class="hidden" :max-datetime="minDatetime" :min-datetime="minDatetime" :type="type" :use12-hour="true" v-on:input="onCalendarInput" :value="calendarValue" value-zone="${_timeZone}" zone="${_timeZone}" />
            </i>
            <input class="invalid" :id="inputId" :name="name" :placeholder="placeholder" type="text" v-if="invalidValue" v-on:change="onValueChange" v-on:focus="$event.target.select()" :value="invalidValue" />
            <input :id="inputId" :name="name" :placeholder="placeholder" type="text" v-else v-on:change="onValueChange" v-on:focus="$event.target.select()" :value="internalValue" />
        </div>
    `,
    watch: {
        id (id) {
            this.inputId = id;
        },
        value (value) {
            this.internalValue = this.getInternalValueFromValue(value);
        }
    }
});
