import './vui-log-event-browser-component.css';
import _AudioDownloadIconComponent from './audio-download-icon-component.js';
import _AudioPlaybackIconComponent from './audio-playback-icon-component.js';
import _dataIoMixin from './data-io-mixin.js';
import _DatetimeInputComponent from './datetime-input-component.js';
import _dialogMixin from './dialog-mixin.js';
import _later from 'isotropic-later';
import _MainComponent from './main-component.js';
import _moment from 'moment-timezone';
import _naturalSort from 'isotropic-natural-sort';
import _PropertyListComponent from './property-list-component.js';
import _timeZone from '../js/time-zone.js';
import _Vue from 'vue/dist/vue.esm.js';

export default _Vue.extend({
    components: {
        'audio-download-icon': _AudioDownloadIconComponent,
        'audio-playback-icon': _AudioPlaybackIconComponent,
        'datetime-input': _DatetimeInputComponent,
        'main-component': _MainComponent,
        'property-list': _PropertyListComponent
    },
    data () {
        return {
            agiChannelVariables: null,
            columns: [
                'id',
                'time',
                'stateName',
                'name',
                'data'
            ],
            filterId: '',
            filterName: '',
            filterStateName: '',
            filterTimeAfter: '',
            filterTimeBefore: '',
            options: {
                filterable: [
                    'filterId',
                    'filterName',
                    'filterStateName',
                    'filterTimeAfter',
                    'filterTimeBefore'
                ],
                filterByColumn: true,
                headings: {
                    data: 'Data',
                    id: 'Id',
                    name: 'Name',
                    stateName: 'State Name',
                    time: 'Time'
                },
                orderBy: {
                    ascending: true,
                    column: 'time'
                },
                perPage: 100,
                perPageValues: [
                    100,
                    250,
                    500,
                    1000
                ],
                preserveState: true,
                async requestFunction (request) {
                    const query = {
                        page: request.page,
                        pageSize: request.limit,
                        vuiId: this.$route.params.vuiId
                    };

                    if (request.orderBy) {
                        query[`sortBy[${request.orderBy}]`] = request.ascending ?
                            'asc' :
                            'desc';
                    }

                    if (request.multiSort) {
                        request.multiSort.forEach(sort => {
                            query[`sortBy[${sort.column}]`] = sort.ascending ?
                                'asc' :
                                'desc';
                        });
                    }

                    if (request.query.filterId) {
                        query.id = request.query.filterId;
                    }

                    if (request.query.filterName) {
                        query.name = request.query.filterName;
                    }

                    if (request.query.filterStateName) {
                        query.stateName = request.query.filterStateName;
                    }

                    if (request.query.filterTimeAfter) {
                        if (request.query.filterTimeBefore) {
                            query.time = `${request.query.filterTimeAfter},${request.query.filterTimeBefore}`;
                        } else {
                            query.time = `${request.query.filterTimeAfter},`;
                        }
                    } else if (request.query.filterTimeBefore) {
                        query.time = `,${request.query.filterTimeBefore}`;
                    }

                    {
                        const {
                            count,
                            vuiLogEvents
                        } = (await this.$parent.$parent.request({
                            json: true,
                            qs: query,
                            url: '/vuiLogEvent'
                        })).body;

                        return {
                            count,
                            data: vuiLogEvents
                        };
                    }
                },
                serverMultiSorting: true,
                sortable: [
                    'id',
                    'name',
                    'stateName',
                    'time'
                ],
                sortIcon: {
                    base: 'fa',
                    down: 'fa-sort-down',
                    is: 'fa-sort',
                    up: 'fa-sort-up'
                },
                texts: {
                    count: 'Showing {from} to {to} of {count} VUI log events|{count} VUI log events|One VUI log event',
                    limit: 'Page Size:',
                    noResults: 'No matching VUI log events'
                }
            },
            vuiStats: null
        };
    },
    filters: {
        formatTimestamp (value) {
            return _moment(value).tz(_timeZone).format('YYYY-MM-DD hh:mm:ss.SSS A');
        }
    },
    methods: {
        onDatetimeFilterChange (filter) {
            _later.asap(() => {
                this.$refs.table.setFilter({
                    [filter]: this[filter]
                });
            });
        },
        onFilterChange (event) {
            const filter = event.target.name.substr(4),
                value = event.target.value;

            this[filter] = value;

            if (!event.target.validity.valid) {
                return;
            }

            this.$refs.table.setFilter({
                [filter]: value
            });
        }
    },
    mixins: [
        _dataIoMixin,
        _dialogMixin
    ],
    async mounted () {
        try {
            const vuiLog = await this.fetchVuiLog(this.$route.params.vuiId);

            this.agiChannelVariables = Object.keys(vuiLog.data.agiChannelVariables || {}).sort(_naturalSort()).reduce((agiChannelVariables, key) => {
                agiChannelVariables[key] = vuiLog.data.agiChannelVariables[key];
                return agiChannelVariables;
            }, {});

            Object.assign(vuiLog, vuiLog.data);
            delete vuiLog.agiChannelVariables;
            delete vuiLog.data;

            this.vuiStats = Object.keys(vuiLog).sort(_naturalSort()).reduce((vuiStats, key) => {
                switch (key) {
                    case 'beginTime':
                    case 'endTime':
                        vuiStats[key] = _moment.tz(vuiLog[key], _timeZone).format('YYYY-MM-DD hh:mm:ss.SSS A');
                        break;
                    default:
                        vuiStats[key] = vuiLog[key];
                }

                return vuiStats;
            }, {});
        } catch (error) {
            this.alert({
                text: 'I\'m sorry, there was an error while loading the VUI log.',
                title: 'Error'
            });
        }
    },
    name: 'vui-log-event-browser',
    template: `
        <main-component class="vui-log-event-browser">
            <div class="pure-g">
                <h3 class="pure-u">
                    VUI Log Browser - {{ this.$route.params.vuiId }}
                </h3>
                <div class="download-vui-log pure-u">
                    <audio-download-icon :append-audio-format="false" :filename="$route.params.vuiId + '.zip'" :options="function (audioFormat) {return {qs: {audioFormat: audioFormat}};}" title="Download VUI Log" type="application/octet-stream" :url="'/vuiLog/' + $route.params.vuiId + '.zip'" />
                </div>
            </div>
            <div v-if="this.vuiStats">
                <table class="pure-table">
                    <thead>
                        <tr>
                            <th>
                                VUI Stats
                            </th>
                            <th>
                                AGI Channel Variables
                            </th>                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="vertical-align-top">
                            <td>
                                <table class="pure-table pure-table-striped">
                                    <tbody>
                                        <tr v-for="(value, key) in this.vuiStats" v-if="key === 'vuiSessionAudioExists' && value">
                                            <td>
                                                VUI Session Audio
                                            </td>
                                            <td class="pure-g text-align-center">
                                                <div class="pure-u-1-2">
                                                    <audio-playback-icon :src="'/vuiSessionAudio/' + $route.params.vuiId" />
                                                </div>
                                                <div class="pure-u-1-2">
                                                    <audio-download-icon :filename="$route.params.vuiId" title="Download VUI Session Audio" :url="'/vuiSessionAudio/' + $route.params.vuiId" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-else-if="key !== 'vuiSessionAudioExists'">
                                            <td>{{ key }}</td>
                                            <td>{{ value }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>
                                <table class="pure-table pure-table-striped">
                                    <tbody>
                                        <tr v-for="(value, key) in this.agiChannelVariables">
                                            <td>{{ key }}</td>
                                            <td>{{ value }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>                            
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                Loading&hellip;
            </div>
            <v-server-table :columns="columns" name="vuiLogEvents" :options="options" ref="table" url="">
                <div slot="beforeLimit" />
                <pre class="font-x-small" slot="data" slot-scope="props">{{ JSON.stringify(props.row.data, null, 4) }}</pre>
                <input class="wide" name="vf__filterId" pattern="^!?(?:(?:[0-9a-f\\-]+)|(?:=[0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}))$$" placeholder="Filter Id" slot="filter__id" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterId" />
                <input class="wide" name="vf__filterName" placeholder="Filter Name" slot="filter__name" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterName" />
                <input class="wide" name="vf__filterStateName" placeholder="Filter State Name" slot="filter__stateName" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterStateName" />
                <div slot="filter__time">
                    <datetime-input name="vf__filterTimeAfter" placeholder="Filter After" v-model="filterTimeAfter" v-on:input="onDatetimeFilterChange('filterTimeAfter')" />
                    <datetime-input name="vf__filterTimeBefore" placeholder="Filter Before" v-model="filterTimeBefore" v-on:input="onDatetimeFilterChange('filterTimeBefore')" />
                </div>
                <div slot="name" slot-scope="props">
                    {{ props.row.name }}
                    <div class="audio-playback" v-if="props.row.data && props.row.data.feedbackAudioId">
                        Feedback Audio
                        <div class="pure-g text-align-center">
                            <div class="pure-u-1-2">
                                <audio-playback-icon :src="'/feedbackAudio/' + props.row.data.feedbackAudioId" />
                            </div>
                            <div class="pure-u-1-2">
                                <audio-download-icon :filename="props.row.data.feedbackAudioId" title="Download Feedback Audio" :url="'/feedbackAudio/' + props.row.data.feedbackAudioId" />
                            </div>
                        </div>
                    </div>
                    <div class="audio-playback" v-if="props.row.data && props.row.data.utteranceAudioId">
                        Utterance Audio
                        <div class="pure-g text-align-center">
                            <div class="pure-u-1-2">
                                <audio-playback-icon :src="'/utteranceAudio/' + props.row.data.utteranceAudioId" />
                            </div>
                            <div class="pure-u-1-2">
                                <audio-download-icon :filename="props.row.data.utteranceAudioId" title="Download Utterance Audio" :url="'/utteranceAudio/' + props.row.data.utteranceAudioId" />
                            </div>
                        </div>
                    </div>
                </div>
                <div slot="stateName" slot-scope="props">{{ props.row.data && props.row.data.stateName }}</div>
                <div slot="time" slot-scope="props">{{
                    props.row.time | formatTimestamp
                }}</div>
            </v-server-table>
        </main-component>
    `
});
